.controls {
	background-color: rgb(255, 255, 255);
	border-radius: 2px;
	border: 1px solid transparent;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	font-family: Roboto;
	font-size: 15px;
	font-weight: 300;
	height: 29px;
	margin-left: 17px;
	margin-top: 10px;
	outline: none;
	padding: 0 11px 0 13px;
	text-overflow: ellipsis;
	width: 200px;
}

.controls:focus {
	border-color: #4d90fe;
}

.title {
	font-weight: bold;
}

#infowindow-content {
	display: none;
}

#map #infowindow-content {
	display: inline;
}

.mapComponentContainer {
	overflow-x: none;
	height: calc(100vh - 150px);
	// padding-bottom: 50px;
	.toggleBtn {
		width: 100px;
		border: none;
		color: white;
		background-color: rgb(197, 56, 56);
		margin-bottom: 10px;
		font-weight: 600;
		height: 25px;
		border-radius: 4px;
		font-size: 14px;
		&:hover {
			cursor: pointer;
		}
	}
	.map-div {
		text-align: center;
	}

	.doneDiv {
		text-align: center;
		.doneBtn {
			height: 25px;
			margin: 15px;
			margin-bottom: 0px;
			margin-top: 10px;
			border: none;
			color: white;
			background-color: #4ca084;
			font-weight: 600;
			width: 100px;
			border-radius: 4px;
			font-size: 14px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}
