.loading {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes ldio {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
.ldio div {
	position: absolute;
	width: 120px;
	height: 120px;
	border: 20px solid #4ca084;
	border-top-color: transparent;
	border-radius: 50%;
}
.ldio div {
	animation: ldio 1s linear infinite;
	top: 100px;
	left: 100px;
}
.loadingio_spinner {
	width: 200px;
	height: 200px;
	display: inline-block;
	overflow: hidden;
}
.ldio {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio div {
	box-sizing: content-box;
}
