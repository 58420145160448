.logo {
	grid-row: 1 / 1;
	grid-column: 1 / 1;
	border-right: 1px solid #f4f6f7;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	@media screen and (max-width: 1200px) {
		display: none;
		grid-row: unset;
		grid-column: unset;
	}
}

.nav {
	grid-row: 2 / -1;
	grid-column: 1 / 2;
	border-right: 1px solid #f4f6f7;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	padding-bottom: 20px;
	&.notInLeads {
		justify-content: flex-end;
	}

	@media screen and (max-width: 1200px) {
		display: none;
		grid-row: unset;
		grid-column: unset;
	}
}

.navIcons {
	// display: flex;
	flex-direction: column;
	// gap: 20px;
	height: 90px;
	min-height: 90px;

	&.overflow {
		height: calc(100vh - 220px);
		overflow: auto;

		.svgHolder {
			position: relative;
			width: 100px;
			height: 30px;
			margin-bottom: 18px;

			.color {
				transition: 0.3s all;
				opacity: 0;
			}
			svg {
				position: absolute;
				top: 0px;
				left: 40%;
			}
			&:hover,
			&.active {
				cursor: pointer;
				svg {
					&.color {
						transition: 0.3s all ease-in-out;
						opacity: 1;
					}
				}
			}
		}
	}
	a {
		color: #cbcbcb;
	}
}
