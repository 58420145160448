.picker {
	position: relative;
}

.swatch {
	width: 28px;
	height: 28px;
	border-radius: 4px;
	border: 3px solid #fff;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.popover {
	position: absolute;
	top: calc(100% + 2px);
	left: 0;
	border-radius: 4px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
	background-color: white;
	input {
		height: 25px;
		border-radius: 4px;
		background-color: #eee;
		border: 1px solid rgba(0, 0, 0, 0.234);
		width: 50%;
		// margin: auto;
		font-size: 15px;
		&:focus {
			outline: none;
		}
	}
}
.eyedropPicker {
	width: 20px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: white;
	padding: 2px;
	&:hover {
		cursor: pointer;
	}
}
