@use '../../styles/layout.scss' as layout;

.container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	.holder {
		width: 360px;
		.logo {
			text-align: center;
			width: 100%;
			height: 70px;
			margin-bottom: 20px;
		}

		.input {
			width: 100%;
			border: none;
			border-bottom: 1px solid #8e96ae;
			margin-bottom: 20px;
			color: #8e96ae;
			padding: 5px;

			&:focus {
				outline: none;
			}

			&.error {
				border-bottom: 1px solid red;
			}
		}
		.label {
			color: #8e96ae;
			font-size: 11px;
			&.error {
				color: red;
			}
		}
		.textfield {
			margin-bottom: 10px;
		}
		.buttonHolder {
			text-align: center;
		}
		button {
			border-radius: 4px;
			box-shadow: 0px 15px 10px rgba(76, 160, 132, 0.166);
			opacity: 1;
			background-color: rgba(76, 160, 132, 1);
			width: 152px;
			height: 39px;
			color: white;
			border: none;
			margin-top: 10px;
			font-size: 14px;
			&:hover {
				cursor: pointer;
			}
		}

		form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			// gap: 10px;
		}
	}
}
