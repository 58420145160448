@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

:root {
	--theme: white;
}

html {
	height: 100%;
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	height: 100%;
	font-family: 'Ubuntu', sans-serif;
}

#root {
	width: 100%;
	height: 100%;
}

main {
	width: 100%;
}

main.grid {
	height: 100%;
	display: grid;
	grid-template-columns: 100px 1fr;
	grid-template-rows: 100px 1fr;
	@media screen and (max-width: 1200px) {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		grid-template-columns: unset;
		grid-template-rows: unset;
	}
}

section {
	background-color: #f4f6f7;
	padding: 50px;
	overflow-y: auto;

	display: flex;
	flex-direction: column;

	align-items: center;
	@media screen and (max-width: 1200px) {
		height: 100%;
		padding: 20px;
		padding-bottom: 0px;
	}
}

a {
	text-decoration: none;
}

img {
	object-fit: contain;
}

p {
	margin: 0;
}

[hidden] {
	display: none !important;
}
