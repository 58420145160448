.modal {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: all 0.3s ease-in-out;
	pointer-events: none;
}

.modal.enterDone {
	opacity: 1;
	pointer-events: visible;
}

.modal.exit {
	opacity: 0;
}

.modalContent {
	width: 554px;
	background-color: #fff;
	border-radius: 4px;
	transition: all 0.3s ease-in-out;
	// transform: translateY(-200px);
}

.modal.enterDone .modalContent {
	// transform: translateY(0);
}

.modal.exit .modalContent {
	// transform: translateY(-200px);
	transition: ease-in-out 0.3s all;
}

.modalFooter {
	padding: 20px;
	height: 74px;
	border-radius: 0px 0px 4px 4px;
	background-color: #f7f9fa;
	display: flex;
	justify-content: right;
	// gap: 5px;

	button:hover {
		cursor: pointer;
	}

	.confirmButton {
		height: 34px;
		width: 106px;
		border: none;
		font-size: 13px;
		background-color: #4ca084;
		color: white;
		border-radius: 4px;
		margin-left: 5px;
	}
	.cancelButton {
		height: 34px;
		width: 90px;
		border: none;
		font-size: 13px;
		background-color: #f7f9fa;
		color: #949494;
	}
}

.modalHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 15px;
	padding-bottom: 15px;
	.modalTitle {
		margin: 0;
		font-size: 18px;
		color: #262626;
		line-height: 18px;
	}

	.modalCloseBtn {
		width: 34px;
		height: 34px;
		border-radius: 4px;
		background-color: #f7f9fa;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			cursor: pointer;
		}
	}
}

.modalBody {
	padding: 10px;
}
