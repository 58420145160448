.modal {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: all 0.3s ease-in-out;
	pointer-events: none;
}

.modal.enterDone {
	opacity: 1;
	pointer-events: visible;
}

.modal.exit {
	opacity: 0;
}

.modalContent {
	width: 554px;
	background-color: #fff;
	border-radius: 4px;
	transition: all 0.3s ease-in-out;
	padding: 10px;
	.image {
		width: 100%;
		max-height: 350px;
	}
	// transform: translateY(-200px);
}
.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 15px;
}
.closeBtn {
	width: 100px;
	transition: 0.3s all;
	border: 1px solid #c53838;
	color: white;
	background-color: #c53838;
	font-size: 14px;
	height: 25px;
	border-radius: 4px;
	margin-right: 10px;

	&:hover {
		cursor: pointer;
	}
}
