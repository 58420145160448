.backButtonDiv {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	.backButton {
		background-color: #488bc9;
		border: none;
		border-radius: 4px;
		box-shadow: 0 15px 10px -5px rgba(72, 139, 201, 0.166);
		color: #fff;
		height: 45px;
		min-width: 82px;
		opacity: 1;
		width: 118px;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover {
			cursor: pointer;
		}
		.backButtonContent {
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				font-size: 14px;
				padding-top: 2px;
				margin-left: 5px;
			}
		}
	}
}
.questionsStats {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 30px;
	.oneQuestionStat {
		width: 100%;
		max-width: 600px;
		background-color: white;
		margin-bottom: 10px;
		padding: 15px;
		border-radius: 4px;
		.statHeader {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.statHeaderLeft {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				.statIndex {
					width: 25px;
					height: 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #488bc9;
					color: white;
					font-size: 12px;
					border-radius: 20px;
					margin-bottom: 0px;
					margin-right: 10px;
				}
				.statTitle {
					margin-top: 5px;
					font-size: 14px;
					width: calc(100% - 35px);
				}
			}
		}
		.statBody {
			&.maxHeight {
				max-height: 150px;
				overflow-y: scroll;
			}
			margin-top: 20px;
			.statHolder {
				width: 100%;
				.progressHolder {
					margin-bottom: 10px;
				}
			}
			.textAnswers {
				table {
					width: 100%;
					text-align: left;
					thead {
						color: #6b6b6b;
						padding: 10px 0px;
					}
					.oneData {
						color: #6b6b6b;
						min-height: 20px;
					}
				}
				.header {
					border: 1px solid gray;
				}
				.statNum {
					width: 50px;
				}
			}
			.allImages {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				max-height: 340px;
				overflow-y: scroll;

				.oneImage {
					width: 33%;
					aspect-ratio: 1/1;
					object-fit: cover;
					margin-bottom: 2px;
					@media screen and(max-width: 400px) {
						width: 49%;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
}
