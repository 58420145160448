.content {
	max-width: 1642px;
	width: 100%;
}
.tabsHolder {
	display: flex;
	justify-content: center;
}
.tabs {
	display: grid;
	grid-template-columns: auto auto auto auto;
	// gap: 3%;
	// row-gap: 10px;
	width: 100%;
	max-width: 885px;
	margin-bottom: 20px;
	@media screen and(max-width: 930px) {
		grid-template-columns: auto auto auto;
	}
	@media screen and(max-width: 700px) {
		grid-template-columns: auto auto;
	}
	&.mobile {
		display: none;
		@media screen and (max-width: 1200px) {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.tab {
		height: 38px;
		width: 210px;
		border-radius: 4px;
		border: 2px solid #e6e6e6;
		background-color: white;
		font-size: 13px;
		color: #a1a1a1;
		margin: 0px 7.5px;
		display: flex;
		justify-content: center;
		align-items: center;
		display: flex;
		justify-content: space-between;
		.iconAndName {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 100%;

			.name {
				font-size: 12px;
				@media screen and (max-width: 700px) {
					display: none;
				}
			}
			.iconHolder {
				position: relative;
				width: 30px;
				height: 100%;

				.color {
					transition: 0.3s all;
					opacity: 0;
				}
				svg {
					position: absolute;
					top: 5px;
					left: 0px;
					margin-right: 5px;
				}
				&.active {
					cursor: pointer;
					svg {
						&.color {
							transition: 0.3s all ease-in-out;
							opacity: 1;
						}
					}
				}
			}
		}
		.infoDiv {
			background-color: #f4f5f7;
			border-radius: 30px;
			width: fit-content;
			font-size: 11px;
			padding: 5px;
			color: #747474;
		}
		&:last-child {
			margin-right: 0px;
		}
		&:first-child {
			margin-left: 0px;
		}
		&.active {
			border: 2px solid #4ca084;
			.name {
				color: #343434;
			}
		}
		&:hover {
			cursor: pointer;
		}
		@media screen and(max-width: 930px) {
			margin: 0 auto;
			margin-bottom: 10px;
			&:last-child {
				margin-right: auto;
			}
			&:first-child {
				margin-left: auto;
			}
		}
		@media screen and(max-width: 480px) {
			width: 180px;
		}
		@media screen and(max-width: 420px) {
			width: 150px;
		}

		// @media screen and(max-width: 1000px) {
		// 	width: 30%;
		// 	margin-bottom: 10px;
		// }
		// @media screen and(max-width: 500px) {
		// 	margin: 5px 5px 0px 5px;
		// }
	}
}

.generalStats {
	display: flex;
	justify-content: center;
	.oneGeneralStat {
		width: 100%;
		margin-bottom: 10px;
		font-size: 18px;
		span {
			color: #4ca084;
		}
	}
}

.leadStatsComponent {
	display: flex;
	justify-content: center;
	.content {
		max-width: 885px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		@media screen and (max-width: 650px) {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
		.oneLead {
			max-width: 100%;
			min-height: 120px;
			height: fit-content;
			// max-height: 330px;
			margin: 0px 5px;
			margin-bottom: 20px;
			background-color: white;
			border-radius: 4px;
			padding: 15px;
			&:nth-child(2n + 1) {
				margin-left: 0px;
			}
			&:nth-child(2n) {
				margin-right: 0px;
			}
			@media screen and (max-width: 650px) {
				&:nth-child(2n + 1) {
					margin: 0px;
					margin-bottom: 20px;
				}
				&:nth-child(2n) {
					margin: 0px;
					margin-bottom: 20px;
				}
			}

			.notes {
				width: 100%;
				height: 100%;
				.title {
					color: #999999;
					border-top: 1px solid #dbdfe2;
					padding-top: 10px;
					margin-bottom: 15px;
				}
				p {
					font-size: 15px;
					color: #727272;
				}
				.notesHolder {
					max-height: 70px;
					height: fit-content;
					overflow-y: scroll;
				}
				.oneNote {
					width: 100%;
					min-height: 20px;
					height: auto;
					padding: 10px;
					text-align: left;
					color: #727272;
					background-color: #f4f5f7;
					border-radius: 4px;
					margin-bottom: 7px;
					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
			.header {
				p {
					margin-bottom: 10px;
					color: #343434;
					font-size: 16px;
					width: fit-content;
					&:hover {
						cursor: pointer;
					}
				}
				border-bottom: 1px solid #dbdfe2;
			}
			.infoGrid {
				display: grid;

				grid-template-columns: repeat(2, minmax(0, 1fr));
				grid-auto-rows: auto;
				// @media screen and (max-width: 750px) {
				// 	grid-template-columns: repeat(3, minmax(0, 1fr));
				// }
				// @media screen and (max-width: 500px) {
				// 	grid-template-columns: repeat(2, minmax(0, 1fr));
				// }
				padding-top: 10px;
				// row-gap: 15px;
				.oneInfo {
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					flex-direction: column;
					// gap: 10px;
					width: 100%;
					min-height: 35px;
					height: auto;
					margin-bottom: 15px;
					font-size: 14px;
					overflow-y: auto;
					overflow-x: hidden;
					.iconHolder {
						width: 26px;
						margin-right: 10px;
					}

					.infoName {
						color: #999999;
						margin-bottom: 10px;
					}
					span {
						color: #343434;
					}
					svg {
						margin-right: 10px;
						width: 26px;
					}
					&.longerCard {
						grid-column: 1/3;
					}
				}
			}
		}
	}
}
