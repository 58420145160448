.header {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;

	.tabs {
		display: flex;
		justify-content: center;
		width: 100%;
		// gap: 15px;
		.tab {
			height: 38px;
			width: 18%;
			min-width: 90px;
			max-width: 210px;
			border-radius: 4px;
			border: 1px solid #e6e6e6;
			background-color: white;
			font-size: 13px;
			color: #a1a1a1;
			margin: 0px 7px;
			&.active {
				border: 2px solid #4ca084;
				color: #262626;
			}
			&:hover {
				cursor: pointer;
			}
			@media screen and(max-width: 1000px) {
				width: 30%;
			}
		}
	}
}
.locationSelect {
	width: 100%;
	max-width: 400px;
	height: 34px;
	position: relative;

	.chosenLocation {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 100%;
	}

	.select {
		background-color: white;
		height: 34px;
		width: 100%;
		max-width: 400px;
		border-radius: 6px;
		border: 1px solid #cecece;
		font-size: 12px;
		padding-left: 5px;
		overflow-x: visible;
		padding-right: 20px;
		-moz-box-shadow: 0 5px 5px -5px rgba(182, 182, 182, 0.75);
		-webkit-box-shadow: 0 5px 5px -5px rgba(182, 182, 182, 0.75);
		box-shadow: 0 5px 5px -5px rgba(182, 182, 182, 0.75);
		appearance: none;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right 0.5rem center;
		background-size: 1em;
		text-overflow: ellipsis;
		white-space: nowrap;
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		&:focus {
			outline: none;
		}

		.selectLocationDiv {
			display: none;
			position: absolute;
			left: -1px;
			top: 28px;
			width: calc(100% + 2px);
			background-color: white;
			border: 1px solid #cecece;
			border-top: none;
			z-index: 2;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
			-moz-box-shadow: 0 5px 5px -5px rgba(182, 182, 182, 0.75);
			-webkit-box-shadow: 0 5px 5px -5px rgba(182, 182, 182, 0.75);
			box-shadow: 0 5px 5px -5px rgba(182, 182, 182, 0.75);
			padding-top: 7px;
			max-height: 100px;
			overflow-x: auto;
			&.active {
				display: block;
			}
			.createNewLocation {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0px 5px;
				margin-bottom: 10px;

				input {
					width: 80%;
					height: 25px;
					border: none;
					background-color: #f0f0f0;
					margin-right: 10px;
					border-radius: 4px;
					color: #666666;

					&:focus {
						outline: none;
					}
				}
				button {
					height: 25px;
					border: none;
					background-color: #4ca084;
					color: white;
					border-radius: 4px;
					font-size: 11px;
					&:hover {
						cursor: pointer;
					}
				}
			}
			.oneLocation {
				padding: 0px 5px;
				min-height: 35px;
				height: fit-content;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				.locationAndEnabled {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					.nameHolder {
						max-width: calc(100% - 55px);
						p {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.activateAndDelete {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						min-width: 55px;

						button {
							width: 25px;
							height: 25px;
							border: none;
							background-color: white;
							color: white;
							border-radius: 4px;
							font-size: 11px;
							&.power {
								width: 30px;
							}
							&:hover {
								cursor: pointer;
							}
						}
					}
				}

				&:last-child {
					border-bottom-left-radius: 6px;
					border-bottom-right-radius: 6px;
				}
				&:hover {
					background-color: #eeeeee;
					.activateAndDelete button {
						background-color: #eeeeee;
					}
				}
			}
		}
	}
}
.editWebsiteDiv {
	margin-top: 20px;
	width: 100%;
	max-width: 1642px;
	.locationContent {
		padding: 15px;
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 750px) {
			flex-direction: column;
			align-items: center;
		}
		.locationCompanyPreview {
			width: 50%;
			@media screen and (max-width: 750px) {
				width: 100%;
			}
			.locationEdit {
				display: flex;
				justify-content: center;
				width: 100%;
				.locationLogoEdit {
					width: 150px;
					display: inline-block;
					margin-right: 10px;
					.locationLogoPreview {
						width: 150px;
						height: 110px;
						// margin-left: 20px;
						margin-bottom: 10px;
						display: flex;
						justify-content: center;
					}

					.locationLogoBtn {
						width: 150px;
						color: white;
						border: none;
						height: 39px;
						background-color: #4ca084;
						font-size: 14px;
						border-radius: 4px;
						&:hover {
							cursor: pointer;
						}
					}
				}
				.locationImageEdit {
					width: 150px;
					display: inline-block;
					margin-left: 10px;

					.locationImagePreview {
						width: 150px;
						height: 110px;
						margin-bottom: 10px;
						img {
							padding-top: 15px;
						}
					}
					.locationImageBtn {
						width: 150px;
						color: white;
						border: none;
						height: 39px;
						background-color: #4ca084;
						font-size: 14px;
						border-radius: 4px;
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
			.inputDiv {
				margin-top: 20px;
				display: flex;
				justify-content: center;

				input {
					width: 263px;
					height: 25px;
					border: 1px solid rgba(0, 0, 0, 0.255);
					margin-right: 20px;
				}
				.clipboard-div {
					&:hover {
						cursor: pointer;
					}
				}
			}
			.colorDiv {
				margin-top: 10px;
				width: 100%;
				.pickers {
					width: 250px;
					margin: auto;
					margin-bottom: 20px;
					.pickersTitle {
						display: flex;
						justify-content: center;
						h3 {
							color: #262626;
						}
					}
					.onePicker {
						width: 100%;
						display: flex;
						margin-bottom: 10px;
						justify-content: space-between;
						align-items: center;
						input {
							// margin-top: 10px;
							// margin-left: 10px
							width: 28px;
							height: 28px;
							padding: 0px 1px;
							border-radius: 4px;
							border: none;
							background-color: white;
							box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), inset 0 0 0 0.5px rgb(0 0 0 / 10%);
						}
						input::-webkit-color-swatch {
							// height: 20px;
							border: none;
							box-shadow: 0 0 0 0.5px rgb(0 0 0 / 10%), inset 0 0 0 0.5px rgb(0 0 0 / 10%);
						}
						p {
							font-weight: 300;
							width: calc(100% - 55px);
							text-align: left;
							padding-left: 20px;
							color: #262626;
						}
					}
				}
			}
			.textMsgDiv {
				display: flex;
				justify-content: center;
				align-items: center;
				// gap: 10px;
				color: #262626;

				textarea {
					font-weight: 300;
					color: #262626;
					margin-left: 10px;
					border: none;
					resize: none;
					background-color: #f3f6f7;
					height: 60px;
					&:focus {
						outline: none;
						background-color: white;
					}
				}
			}
			.changeDeviceDiv {
				width: 100%;
				// height: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				// gap: 10px;
				padding: 29px;
				padding-bottom: 15px;
				@media screen and(max-width: 1000px) {
					flex-direction: column;
				}
				.input {
					width: 130px;
					min-width: 130px;
					margin-right: 10px;
					@media screen and (max-width: 1000px) {
						margin-bottom: 10px;
						margin-right: 0px;
					}
				}
				.changeButton {
					border-radius: 4px;
					opacity: 1;
					background-color: rgb(76, 160, 132);
					width: 175px;
					font-size: 15px;
					height: 40px;
					color: white;
					border: none;

					&.red {
						background-color: rgb(179, 0, 0);
					}
					&:hover {
						cursor: pointer;
					}
				}
			}

			.googleTagDiv {
				margin-top: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				// gap: 10px;
				input {
					height: 39px;
					padding-left: 10px;
					padding-right: 10px;
					border-radius: 4px;
					border: 1px solid black;
					&:focus {
						outline: none;
					}
				}
				.saveBtn {
					width: 175px;
					border: none;
					color: white;
					height: 39px;
					font-size: 14px;
					background-color: #4ca084;
					border-radius: 4px;
					&:hover {
						cursor: pointer;
					}
				}
			}
			.saveDiv {
				text-align: center;
				margin-bottom: 20px;
				.saveBtn {
					width: 150px;
					border: 1px solid #4ca084;
					color: #4ca084;
					height: 30px;
					font-weight: 600;
					background-color: white;
				}
			}
		}
		.locationMobile {
			float: right;
			width: 50%;
			display: flex;
			justify-content: center;
			@media screen and (max-width: 750px) {
				width: 260px;
			}
			.mobileDiv {
				width: 250px;
				position: relative;
				img {
					position: absolute;
					width: 255px;
				}
				.mobileView {
					position: absolute;
					top: 0;
					left: 0;
					width: 260px;
					height: 500px;
					padding-top: 18px;
					padding-left: 18px;
					padding-right: 18px;
					padding-bottom: 10px;

					.imageView {
						img {
							width: 220px;
						}
					}
					.logoView {
						position: absolute;
						top: 110px;
						left: 80px;
						width: 95px;
						height: 95px;
						border-radius: 5px;
						box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

						img {
							width: 80px;
							height: 80px;
							margin-left: 7px;
							margin-top: 7px;
							border-radius: 5px;
						}
					}
					.tnxMsgDiv {
						position: absolute;
						top: 220px;
						left: 30px;
						p {
							font-size: 14px;
							width: 200px;
							text-align: center;
						}
					}
					.background {
						width: 100%;
						height: 100%;
					}
					.links {
						position: absolute;
						top: 280px;
						left: 28px;
						height: 220px;
						overflow-y: hidden;
						.link {
							height: 35px;
							width: 200px;
							border-radius: 5px;
							margin-bottom: 10px;
							text-align: center;
							p {
								margin: 0;
								font-size: 14px;
								line-height: 35px;
							}
						}
					}
				}
			}
		}
	}
}
.editLinksDiv {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 20px;
	width: 100%;
	.dragContainer {
		width: 60%;
		margin-top: 10px;

		@media screen and (max-width: 1300px) {
			width: 100%;
		}
		.draggableItem * {
			color: #262626;
		}

		.item {
			position: relative;
			background-color: rgba(62, 199, 130, 0.296);
			width: 100%;
			height: 140px;
			float: left;
			// padding-top: 20px;
			border-radius: 4px;
			margin-bottom: 15px;
			background-color: white;
			padding-left: 15px;
			padding-right: 15px;
			&.new {
				height: 160px;
			}
			&.download {
				height: 170px;
			}
			&.card {
				height: 245px;

				&.longer {
					height: 335px;
				}
			}
			.title {
				font-weight: 200;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.title.editable {
				margin-bottom: 0px;
				// margin-left: 32px;
				margin: 0px;
			}
			.switch {
				margin-right: 10px;
			}
			.buttons {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				.editDiv,
				.deleteDiv {
					&:hover {
						cursor: pointer;
					}
				}
				.deleteDiv {
					margin-right: 10px;
				}
				.editDiv {
					margin-right: 10px;
				}
			}
			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 35px;
				// padding-right: 25px;
				// &.hasInput {
				// 	margin-top: 8px;
				// 	margin-bottom: 8.5px;
				// }
				.title.editable {
					margin-top: 0px;
					// margin-left: 32px;
					margin: 0px;
				}
				.buttons {
					display: flex;
					justify-content: flex-end;
					align-items: center;

					.editDiv,
					.deleteDiv {
						&:hover {
							cursor: pointer;
						}
					}
					.deleteDiv {
						margin-right: 10px;
					}
					.editDiv {
						margin-right: 10px;
					}
				}
			}
			.dragIcon {
				height: 30px;
				width: 40px;
			}
			.googleDiv {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 35px;
				padding-top: 20px;
				p {
					text-align: left;
					margin-left: 0px;
				}
				button {
					font-size: 14px;
					border: none;
					border-radius: 4px;
					width: 100px;
					height: 30px;
					color: white;
					background-color: #4ca084;

					&:hover {
						cursor: pointer;
						transition: 0.3s all;
						color: white;
						background-color: #4ca084;
					}
				}
				.placeNdrag {
					display: flex;
					justify-content: space-between;
					align-items: center;
					button {
						font-size: 14px;
						border-radius: 4px;
					}
				}
				.dragDiv {
					height: 30px;
					// padding-top: 10px;
					width: 40px;
				}
			}
			.vCardDiv {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 35px;
				padding-top: 20px;
				p {
					text-align: left;
					margin-left: 0px;
				}
				button {
					font-size: 14px;
					border: none;
					border-radius: 4px;
					width: 100px;
					height: 30px;
					color: white;
					background-color: #4ca084;

					&:hover {
						cursor: pointer;
						transition: 0.3s all;
						color: white;
						background-color: #4ca084;
					}
				}
				.placeNdrag {
					display: flex;
					justify-content: space-between;
					align-items: center;
					button {
						font-size: 14px;
						border-radius: 4px;
					}
				}
				.dragDiv {
					height: 30px;
					// padding-top: 10px;
					width: 40px;
				}
			}
			.inputDiv {
				width: 100%;
				input {
					width: 100%;
				}
				&.multiple {
					display: grid;
					grid-template-columns: repeat(3, minmax(0, 1fr));
					.inputHolder {
						padding: 0px 10px;
						@media screen and (max-width: 550px) {
							padding: 0px 5px;
						}
						&:nth-child(3n + 1) {
							padding-left: 0px;
						}
						&:nth-child(3n) {
							padding-right: 0px;
						}
						&.multiple {
							display: flex;
							justify-content: space-between;
							input {
								&:last-child {
									margin-right: 0px;
								}
								&.narrow {
									width: 60%;
								}
							}
						}
					}
					input {
						width: 100%;
						margin-right: 10px;
						&::-webkit-outer-spin-button,
						&::-webkit-inner-spin-button {
							-webkit-appearance: none;
							margin: 0;
						}

						/* Firefox */
						&[type='number'] {
							-moz-appearance: textfield;
						}
						&.error {
							border: 1px solid rgb(192, 0, 0);
						}
					}
				}
			}
			.moreButtonHolder {
				display: flex;
				justify-content: center;
				button {
					display: flex;
					align-items: center;
					border: none;
					background-color: white;
					height: 16px;
					min-width: 75px;
					font-size: 11px;
					padding: 0px;
					&:hover {
						cursor: pointer;
					}
					svg {
						transition: 0.3s all ease-in-out;

						&.up {
							transition: 0.3s all ease-in-out;
							transform: rotate(180deg);
						}
					}
				}
			}
			input {
				width: 100%;
				height: 35px;
				border-radius: 4px;
				background-color: #f4f6f7;
				font-size: 13px;
				border: none;
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 10px;
				// margin-top: 35px;
				&:focus {
					outline: none;
				}
			}
			// p {
			// 	text-align: left;
			// 	margin-left: 35px;
			// }
		}
		.newItem * {
			color: #262626;
		}
		.newItem {
			position: relative;
			background-color: rgba(62, 199, 130, 0.296);
			width: 100%;
			height: 170px;
			border-radius: 4px;
			margin-bottom: 15px;
			background-color: white;
			padding: 15px;
			padding-top: 5px;
			box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.179);

			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 20px;

				.title {
					font-size: 16px;
				}
				.selectAndAdd {
					display: flex;
					justify-content: flex-end;
					// gap: 15px;

					select {
						width: 170px;
						border-radius: 4px;
						appearance: none;
						background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
						background-repeat: no-repeat;
						background-position: right 0.5rem center;
						background-size: 1em;
						padding: 3px;
						margin-right: 15px;
						&:focus {
							outline: none;
						}
					}
					.addBtn {
						font-size: 14px;
						border: none;
						border-radius: 4px;
						width: 100px;
						height: 30px;
						color: white;
						background-color: #4ca084;
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
			.linkValue {
				width: 100%;
				height: 35px;
				border-radius: 4px;
				background-color: #f4f6f7;
				font-size: 13px;
				border: none;
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 10px;
				&.red {
					border: 1px solid red;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}
}
