.header {
	grid-row: 1 / 2;
	grid-column: 2 / -1;
	border-bottom: 1px solid #f4f6f7;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding: 10px 20px;
	@media screen and (max-width: 1200px) {
		width: 100%;
		max-height: 100px;
	}
}

.overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	background-color: white;
	overflow-x: hidden;
	transition: 0.5s;
	&.opened {
		width: 100%;
		.overlayContent {
			left: 20px;
		}
		.burgerLogo {
			left: 20px;
		}
	}
}

.overlayContent {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: -300px;
	width: 100%;
	text-align: center;
	// margin-top: 30px;
	white-space: nowrap;
	touch-action: none;
	-webkit-font-smoothing: antialiased;
	transition: 0.4s;

	.navigationTop {
		display: flex;
		align-items: center;
		flex-direction: column;
		// gap: 20px;
		.navItem {
			display: flex;
			width: 200px;
			// gap: 10px;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 20px;
			.iconHolder {
				position: relative;
				width: 24px;
				height: 24px;
				margin-right: 10px;
				.color {
					transition: 0.3s all;
					opacity: 0;
				}
				svg {
					position: absolute;
					top: 0px;
					left: 0px;
				}
			}
			&:hover,
			&.active {
				cursor: pointer;
				.iconHolder {
					svg {
						&.color {
							transition: 0.3s all ease-in-out;
							opacity: 1;
						}
					}
				}
			}

			&.active {
				p {
					width: auto;
					opacity: 1;
					transform: translateX(0px);
					transition: 0.3s all ease-in-out;
					transition-delay: 0.1s;

					color: #343434;
				}
			}
		}
	}

	.navigationBottom {
	}
}

.overlay a {
	padding: 8px;
	text-decoration: none;
	font-size: 36px;
	color: #818181;
	display: block;
	transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
	color: #f1f1f1;
}

.overlay .closeBtn {
	position: absolute;
	top: 0px;
	right: 25px;
	font-size: 40px;
}
.overlay .burgerLogo {
	position: absolute;
	top: 20px;
	transition: 0.4s;
	left: -300px;
}

.headerItems {
	display: flex;
	// flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	&.icons {
		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
	&.mobile {
		@media screen and (max-width: 1200px) {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.burgerMenu {
			display: none;
			width: 24px;

			@media screen and (max-width: 1200px) {
				display: block;
			}
		}
	}
}

// .headerItem {
// 	a {
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: center;
// 		align-items: center;
// 		gap: 5px;
// 		color: #cbcbcb;

// 		img {
// 			width: 30px;
// 			height: 30px;
// 		}

// 		p {
// 			margin: 0;
// 			font-size: 1.2rem;
// 		}
// 	}

// 	a:hover {
// 		p {
// 			// color: black;
// 			text-decoration: underline;
// 		}
// 	}
// }

.headerItem {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 30px;

	.iconHolder {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 24px;
		height: 24px;
		.color {
			transition: 0.3s all;
			opacity: 0;
		}
		svg {
			position: absolute;
			top: 0px;
			left: 0px;
		}
	}
	p {
		width: 0px;
		opacity: 0;
		transform: translateX(-20px);
		transition: 0s all ease-in-out;
		padding-left: 10px;
		font-size: 14px;
		color: #ababab;
	}
	&:hover,
	&.active {
		cursor: pointer;
		.iconHolder {
			svg {
				&.color {
					transition: 0.3s all ease-in-out;
					opacity: 1;
				}
			}
		}
	}

	&.active {
		p {
			width: auto;
			opacity: 1;
			transform: translateX(0px);
			transition: 0.3s all ease-in-out;
			transition-delay: 0.1s;

			color: #343434;
		}
	}
}

.profile {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// gap: 10px;

	img {
		width: 40px;
		height: 40px;
	}

	p {
		font-size: 1.2rem;
		color: #9c9c9c;
		margin-left: 10px;
	}

	.headerArrow {
		width: 15px;
		height: 15px;
	}
}

.lang {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// gap: 10px;
}

.dropdown {
	position: relative;
	padding: 10px;
}

.dropdownContent {
	display: none;
	position: absolute;
	min-width: 150px;
	min-height: 200px;
	top: 45px;
	right: 0px;
	z-index: 1;
	background-color: white;

	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
	border: 1px solid #9c9c9c;
	border-radius: 10px;
}

.languageDiv {
	position: relative;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		margin-right: 10px;
		font-size: 1.2rem;
		color: #9c9c9c;
	}
	.headerArrow {
		width: 15px;
		height: 15px;
	}
}

.dropdownLanguage {
	display: none;
	position: absolute;
	min-width: 160px;
	min-height: 50px;
	top: 35px;
	right: 0px;
	z-index: 1;
	background-color: white;

	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border: 1px solid #9c9c9c;
	border-radius: 10px;

	button {
		width: 100%;
		border: none;
		background-color: white;
		height: 40px;
		transition: 0.3s all;

		&:hover {
			cursor: pointer;
			transition: 0.3s all;
			background-color: rgb(240, 240, 240);
		}
		&:first-child {
			border-radius: 10px 10px 0px 0px;
		}
		&:last-child {
			border-radius: 0px 0px 10px 10px;
		}
	}
}

.languageDiv:hover .dropdownLanguage {
	display: flex;
}
.dropdown:hover .dropdownContent {
	display: flex;
}
