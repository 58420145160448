.newSurveyText {
	color: #b4b4b4;
	font-size: 13px;
	padding: 0px 10px;
}
.newSurveyInputHolder {
	padding: 0px 10px;
	width: 100%;
	.newSurveyInput {
		margin-top: 10px;
		margin-bottom: 30px;
		width: 100%;
		height: 35px;
		border-radius: 4px;
		border: 1px solid #dedede;
		&:focus {
			outline: none;
		}
	}
}
.allSurveys {
	display: flex;
	justify-content: center;
	max-width: 435px;
	flex-wrap: wrap;
	margin-top: 30px;

	// .block:nth-child(3n + 1) {
	//   margin-right: 10px;
	// }
	// .block:nth-child(3n + 2) {
	//   margin-right: 10px;
	// }
	.oneSurvey {
		width: 135px;
		height: 140px;
		margin: 0px 5px;
		margin-bottom: 10px;
		background-color: white;
		border-radius: 4px;
		padding: 10px 13px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
		.downInfo {
			width: 100%;
		}
		.info {
			.title {
				font-weight: bold;
				color: #79889d;
				font-size: 13px;
				margin-bottom: 5px;
				max-height: 45px;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-moz-box-orient: vertical;
				-ms-box-orient: vertical;
				box-orient: vertical;
				-webkit-line-clamp: 3;
				-moz-line-clamp: 3;
				-ms-line-clamp: 3;
				line-clamp: 3;
			}
		}
		.createdDiv {
			margin-bottom: 10px;
			.createdDate {
				font-size: 12px;
				color: #79889d;
			}
		}
		.editButtons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			svg {
				margin-right: 5px;

				&:hover {
					cursor: pointer;
				}
			}
			// svg:first-child {
			// }
			// svg:last-child {
			//   margin-right: 20px;
			// }
		}
	}
	.addSurvey {
		width: 135px;
		height: 140px;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: white;
		margin: 0px 5px;

		margin-bottom: 10px;
		padding: 14px;
		&:hover {
			cursor: pointer;
		}
		// &.noMargin {
		//   margin-right: 0px;
		// }
		p {
			color: #79889d;
			font-size: 14px;
			text-align: center;
			margin-top: 5px;
		}

		.addCircle {
			width: 50px;
			height: 50px;
			border-radius: 30px;
			background-color: #79889d;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 27px;
		}
	}
}
