.surveyButtonHolder {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	.backButton {
		border-radius: 4px;
		box-shadow: 0px 15px 10px -5px rgba(72, 139, 201, 0.166);
		opacity: 1;
		background-color: rgba(72, 139, 201, 1);
		border: none;
		height: 45px;
		width: 118px;
		color: white;
		min-width: 82px;

		.backButtonContent {
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				font-size: 14px;
				padding-top: 2px;
				margin-left: 5px;
			}
		}

		&:hover {
			cursor: pointer;
		}
	}
}
.surveyInfo {
	width: 100%;
	max-width: 900px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	@media screen and (max-width: 800px) {
		flex-direction: column;
		align-items: center;
	}
	.surveyAllInfo {
		width: calc(100% - 300px);
		@media screen and(max-width: 800px) {
			width: 100%;
			margin-bottom: 20px;
		}
		.newQuestionAndSaveBtns {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;

			.saveSurveyButton {
				height: 40px;
				width: 170px;
				font-size: 12px;
				border-radius: 4px;
				border: 1.5px solid #7eafda;
				background-color: #d0e9ff;
				color: #7eafda;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 5px;
				&:hover {
					cursor: pointer;
				}
				svg {
					width: 20px;
					height: 20px;
					margin-right: 5px;
				}
			}
			.newSurveyButton {
				background-color: white;
				width: 170px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: none;
				border-radius: 4px;
				height: 40px;
				color: #8290a2;
				font-size: 12px;
				margin-right: 5px;
				&:hover {
					cursor: pointer;
				}
				svg {
					width: 20px;
					height: 20px;
					margin-right: 5px;
				}
			}
		}
		.surveyNewQuestion {
			margin-top: 10px;
			width: 100%;
			// min-height: 130px;
			height: fit-content;
			border-radius: 4px;
			background-color: white;
			padding: 15px 20px;
			margin-bottom: 20px;
			font-size: 14px;
			.addChoiceButton {
				border: 1px solid #478bc8;
				background-color: #d0e9ff;
				color: #4a8ccb;
				padding: 0.5rem;
				border-radius: 4px;
				width: 140px;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 10px;
				&:hover {
					cursor: pointer;
				}
				svg {
					margin-right: 5px;
				}
			}
			.oneChoice {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 8px;
				position: relative;
				.moveChoice {
					position: absolute;
					left: 0px;
					top: 10px;
				}
				.choiceInput {
					width: 250px;
					margin-top: 0px;
					margin-right: 10px;
					font-size: 14px;
				}
				.optionTrash {
					cursor: pointer;
				}
			}
			.typeAndRequired {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-top: 10px;
				.questionTypeDiv {
					width: 50%;
					select {
						-webkit-appearance: none;
						-moz-appearance: none;
						background: transparent;
						background-image: url('../../../../assets/up-down-arrows.svg');
						background-repeat: no-repeat;
						background-position-x: 98%;
						background-position-y: 10px;
						border: 1px solid #dfdfdf;
						border-radius: 4px;
						margin-right: 2rem;
						color: #7e8ea2;
						padding: 0.5rem;
						font-size: 14px;
						padding-right: 1rem;
						margin-top: 5px;
						width: 100%;
						&:focus {
							outline: none;
						}
					}
				}
				.questionRequiredDiv {
					width: 50%;
					margin-left: 20px;
					.surveyRequiredButtons {
						margin-top: 5px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						button {
							border: 1px solid #dfdfdf;
							background-color: white;
							width: 48%;
							padding: 0.5rem;
							border-radius: 4px;
							color: #7e8ea2;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 14px;
							svg {
								margin-right: 5px;
							}
							&.active {
								border: 1px solid #478bc8;
								background-color: #d0e9ff;
								color: #4a8ccb;
							}
							&:hover {
								cursor: pointer;
							}
						}
					}
				}
			}
			.newQuestionButtons {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				padding-top: 10px;
				margin-top: 10px;
				border-top: 1px solid #dedede;
				button {
					border: none;
					border-radius: 4px;
					height: 30px;
					font-size: 12px;

					display: flex;
					justify-content: center;
					align-items: center;
					padding: 0px 15px;
					&:hover {
						cursor: pointer;
					}

					&.cancelQuestion {
						color: #2c3a4e;
						background-color: #f8f9fd;
						margin-right: 10px;
					}
					&.addQuestion {
						color: white;
						background-color: #4a8bc8;

						svg {
							margin-left: 5px;
						}
					}
				}
			}
			.questionHeader {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 10px;
				.questionIndex {
					width: 25px;
					height: 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #488bc9;
					color: white;
					font-size: 12px;
					border-radius: 20px;
				}
				p {
					text-overflow: ellipsis;
					max-width: 100%;
				}
			}
			input {
				margin-top: 10px;
				width: 100%;
				height: 35px;
				border-radius: 4px;
				border: 1px solid #dedede;
				&.error {
					border: 1px solid red;
				}
				&:focus {
					outline: none;
				}
			}
		}
	}
	.surveyMainInfo {
		background-color: white;
		width: 100%;
		border-radius: 4px;
		padding: 15px 20px;
		p {
			color: #21374f;
			font-size: 13px;
		}
		input {
			margin-top: 10px;
			margin-bottom: 20px;
			width: 100%;
			height: 35px;
			border-radius: 4px;
			border: 1px solid #dedede;
			&:focus {
				outline: none;
			}
			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	.surveyQuestion {
		width: 100%;
		// min-height: 130px;
		height: fit-content;
		border-radius: 4px;
		background-color: white;
		position: relative;
		p {
			font-size: 14px;
		}
		.surveyEditableQuestionP {
			margin-top: 10px;
		}
		.questionDrag {
			position: absolute;
			left: -40px;
			width: 20px;
			top: 0px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.choicesPreview {
			width: 100%;
			display: grid;
			justify-content: space-between;
			grid-template-columns: repeat(auto-fit, minmax(125px, 125px));
			margin-top: 10px;
			.oneChoicePreviewCover {
				padding: 5px;
				border: 1px solid #dfdfdf;
				border-radius: 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 5px;
				height: 41px;

				.oneChoicePreview {
					width: auto;

					// height: fit-content;
					// max-height: 50px;
					color: #7e8ea2;
					font-size: 13px;
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-moz-box-orient: vertical;
					-ms-box-orient: vertical;
					box-orient: vertical;
					-webkit-line-clamp: 2;
					-moz-line-clamp: 2;
					-ms-line-clamp: 2;
					line-clamp: 2;
					// padding: 4px;
				}
			}
		}
		.questionHeader {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// margin-bottom: 10px;
			.editQuestion {
				background-color: white;
				border: none;
				&:hover {
					cursor: pointer;
				}
				.closeQuestion {
					transform: rotate(180deg);
				}
			}
			.questionHeaderLeft {
				display: flex;
				align-items: center;

				.questionIndex {
					margin-bottom: 0px;
					margin-right: 10px;
					min-width: 25px;
				}
				p {
					text-overflow: ellipsis;
					max-width: 100%;
				}
			}
			.questionHeaderRight {
				display: flex;
				justify-content: right;
				align-items: center;
				.trash {
					margin-top: 1px;
				}
			}
		}
		.choicesDiv {
			.choicesTitle {
				margin: 6px 0px;
			}
			.addChoiceButton {
				border: 1px solid #478bc8;
				background-color: #d0e9ff;
				color: #4a8ccb;
				padding: 0.5rem;
				border-radius: 4px;
				width: 140px;
				font-size: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 10px;
				&:hover {
					cursor: pointer;
				}
				svg {
					margin-right: 5px;
				}
			}
			.oneChoice {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 8px;
				position: relative;
				.moveChoice {
					position: absolute;
					left: 0px;
					top: 10px;
				}
				.choiceInput {
					width: 250px;
					margin-top: 0px;
					margin-right: 10px;
					font-size: 14px;
				}
				.optionTrash {
					cursor: pointer;
				}
			}
		}
		.typeAndRequired {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin-top: 10px;
			.questionTypeDiv {
				width: 50%;
				select {
					-webkit-appearance: none;
					-moz-appearance: none;
					background: transparent;
					background-image: url('../../../../assets/up-down-arrows.svg');
					background-repeat: no-repeat;
					background-position-x: 98%;
					background-position-y: 10px;
					border: 1px solid #dfdfdf;
					border-radius: 4px;
					margin-right: 2rem;
					color: #7e8ea2;
					padding: 0.5rem;
					font-size: 14px;
					padding-right: 1rem;
					margin-top: 5px;
					width: 100%;
					&:focus {
						outline: none;
					}
				}
			}
			.questionRequiredDiv {
				width: 50%;
				margin-left: 20px;
				.surveyRequiredButtons {
					margin-top: 5px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					button {
						border: 1px solid #dfdfdf;
						background-color: white;
						width: 48%;
						padding: 0.5rem;
						border-radius: 4px;
						color: #7e8ea2;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 14px;
						svg {
							margin-right: 5px;
						}
						&.active {
							border: 1px solid #478bc8;
							background-color: #d0e9ff;
							color: #4a8ccb;
						}
						&:hover {
							cursor: pointer;
						}
					}
				}
			}
		}
		input {
			margin-top: 10px;
			width: 100%;
			height: 35px;
			border-radius: 4px;
			border: 1px solid #dedede;
			&:focus {
				outline: none;
			}
		}
		.questionIndex {
			width: 25px;
			height: 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #488bc9;
			color: white;
			font-size: 12px;
			border-radius: 20px;
		}
	}
	.phonePreview {
		width: 270px;
		position: relative;
		height: 520px;

		@media screen and (max-width: 800px) {
			display: flex;
			justify-content: center;
		}
		img {
			position: absolute;
			top: 0px;
			left: 0px;
		}
		.phoneContent {
			width: 270px;
			position: absolute;
			top: 25px;
			left: 0px;
			padding: 18px;
			padding-left: 19.5px;
			padding-bottom: 10px;
			height: 510px;

			.allPhoneContent {
				position: relative;
				height: 100%;
				overflow: hidden;
			}
			.banner {
				display: flex;
				justify-content: center;
				width: 232px;
				img {
					position: static;
					width: 200px;
					border-radius: 10px;
				}
			}
			.title {
				display: flex;
				justify-content: center;
				width: 232px;
				p {
					margin-top: 10px;
					max-width: 200px;
					color: #78889b;
					font-size: 17px;
					text-align: center;
				}
			}
			.welcomeMessage {
				display: flex;
				justify-content: flex-start;
				width: 232px;
				padding-left: 16px;
				margin-top: 10px;
				.message {
					border-radius: 14px;
					max-width: 185px;
					border-bottom-left-radius: 0px;
					background-color: white;
					font-size: 9px;
					padding: 5px 10px;
					color: #2e4359;
				}
			}
			.surveyQuestionPreview {
				padding-left: 16px;

				.answerHolder {
					display: flex;
					justify-content: flex-end;
					padding-right: 16px;

					.message.answer {
						background-color: #5cb89f;
						color: white;
						text-align: center;
						border-radius: 14px;
						border-bottom-right-radius: 0px;
						width: fit-content;
					}
				}

				.message {
					border-radius: 10px;
					max-width: 185px;
					border-bottom-left-radius: 0px;
					background-color: white;
					font-size: 9px;
					padding: 8px 12px;
					color: #2e4359;
					margin-top: 5px;
					.messageTop {
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;
					}

					.answersHolderPreview {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						flex-wrap: wrap;

						.oneAnswerPreview {
							display: flex;
							justify-content: center;
							align-items: center;
							margin-top: 5px;
							color: #78889d;
							background-color: white;
							border: 0.5px solid #d6d9e4;
							border-radius: 5px;
							padding: 5px 7px;
							font-size: 8px;
							margin-right: 4px;
							min-height: 23.5px;
							&.active {
								background-color: #baffec;
								border: 0.5px solid #5cb89f;
								color: #61bda3;
							}
						}
					}

					p {
						max-width: calc(100% - 19px);
						margin-top: 2px;
					}
					.questionIndex {
						width: 15px;
						height: 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #488bc9;
						color: white;
						font-size: 7px;
						border-radius: 20px;
						margin-right: 4px;
						padding: 0px;
					}
				}
			}
		}
	}
}
