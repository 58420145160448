.cropperComponentContainer {
	.toggleBtn {
		width: 100px;
		transition: 0.3s all;
		border: 1px solid #c53838;
		color: white;
		background-color: #c53838;
		font-size: 14px;
		height: 25px;
		border-radius: 4px;
		margin-right: 10px;

		&:hover {
			cursor: pointer;
		}
	}
	.cropDiv {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		// gap: 10px;

		.cropBtn {
			width: 100px;
			transition: 0.3s all;
			border: 1px solid #4ca084;
			font-size: 14px;
			color: white;
			border-radius: 4px;
			background-color: #4ca084;
			height: 25px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
