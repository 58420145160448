.containerStyles {
  height: 30px;
  width: 100%;
  background-color: #f6f7fb;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
}
.fillerStyles {
  height: 100%;
  background-color: #d1ecfd;
  text-align: left;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.progressText {
  position: absolute;
  left: 7px;
  top: 9px;
  font-size: 12px;
  color: #222222;
  z-index: 2;
  b {
    font-weight: bold;
  }
}
.labelStyles {
  font-size: 14px;
  max-height: 25px;
  padding: 5px;
  color: #222222;
}
